import * as React from 'react'
import { Helmet } from 'react-helmet'

type Props = {
	title: string
}

export const PageTitle: React.FC<Props> = ({ title }) => {
	return (
		<Helmet>
			<title>{title}</title>
		</Helmet>
	)
}
