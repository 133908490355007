import React from 'react'

import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate
} from '@azure/msal-react'
import { Helmet } from 'react-helmet'

import LayoutFullPage from '@components/layouts/LayoutFullPage'
import { PageTitle } from '@components/configs/PageTitle'
import {
	ButtonLink,
	buttonStyle,
	wrapperStyle
} from '@components/ui/ButtonLink'

import { Colors } from '@utils/css-variables'

import { makeClasses } from '@utils/styles'
import { AppStateContext } from '@components/layouts/DynamicLayout'

import { formatStrapiText } from '@utils/methods'

const classes = makeClasses({
	root: {
		margin: 'auto'
	},
	primaryButtonStyle: {
		...buttonStyle,
		'&:hover': {
			background: Colors.primary,
			borderColor: Colors.primary
		}
	},
	secondaryButtonStyle: {
		...buttonStyle,
		background: 'transparent',
		color: Colors.secondary
	},
	mainTitle: {
		display: 'flex',
		justifyContent: 'center'
	},
	wrapperStyle
})

export default function NotFoundPage() {
	return (
		<AppStateContext.Consumer>
			{({ pageData }) => {
				return (
					<>
						<AuthenticatedTemplate>
							<Helmet>
								<style>{`body { background-color: ${Colors.backgroundConnected}; }`}</style>
							</Helmet>
							<LayoutFullPage>
								<PageTitle title={formatStrapiText(pageData.title)} />
								<div className={classes.root}>
									<h1 className={classes.mainTitle}>Page non disponible</h1>
									<div className={classes.wrapperStyle}>
										<ButtonLink
											link="/auth/requests"
											text="Mes requêtes"
											type="secondaryButton"
										/>
									</div>
								</div>
							</LayoutFullPage>
						</AuthenticatedTemplate>
						<UnauthenticatedTemplate>
							<LayoutFullPage>
								<PageTitle title={formatStrapiText(pageData.title)} />
								<div className={classes.root}>
									<h1 className={classes.mainTitle}>Page non disponible</h1>
									<div className={classes.wrapperStyle}>
										<ButtonLink
											link="/"
											text="Accueil"
											type="secondaryButton"
										/>
									</div>
								</div>
							</LayoutFullPage>
						</UnauthenticatedTemplate>
					</>
				)
			}}
		</AppStateContext.Consumer>
	)
}
