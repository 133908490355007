import * as React from 'react'

import { Colors, ButtonLinkTypeEnum, Constants } from '@utils/css-variables'
import { ButtonLinkType } from '../../typings/shared'
import { joinClasses, makeClasses } from '@utils/styles'
import Link from '@components/ui/link'

type Props = {
	text: string
	link?: string
	type?: ButtonLinkType
	wrapperClassName?: string
	disabled?: boolean
	onclick?: () => void
}

export const wrapperStyle = {
	display: 'block',
	height: '60px',
	width: '250px',
	margin: 'auto',
	textAlign: 'center' as 'center'
}

export const buttonStyle = {
	lineHeight: '1em',
	background: Colors.secondary,
	color: Colors.white,
	textDecoration: 'none',
	padding: '15px 30px',
	borderRadius: '23px',
	border: `1px solid ${Colors.secondary}`,
	fontWeight: 500,
	fontSize: '16px',
	margin: '0 5px',
	display: 'block',
	cursor: 'pointer',
	fontFamily: Constants.fontStack
}

const classes = makeClasses({
	[ButtonLinkTypeEnum.PRIMARY_BUTTON]: {
		...buttonStyle,
		'&:hover': {
			background: Colors.primary,
			borderColor: Colors.primary
		}
	},
	[ButtonLinkTypeEnum.DISABLED_BUTTON]: {
		...buttonStyle,
		background: Colors.lightGrey6,
		borderColor: Colors.porcelainGrey,
		color: Colors.white
	},
	[ButtonLinkTypeEnum.SECONDARY_BUTTON]: {
		...buttonStyle,
		background: 'transparent',
		color: Colors.secondary
	},
	[ButtonLinkTypeEnum.LINK]: {
		background: 'transparent',
		color: Colors.secondary,
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	wrapperStyle
})

export const ButtonLink: React.FC<Props> = ({
	link,
	text,
	type,
	disabled,
	wrapperClassName = '',
	onclick
}) => {
	const localType: ButtonLinkType =
		!type || type === 'button'
			? (ButtonLinkTypeEnum.PRIMARY_BUTTON as ButtonLinkType)
			: type

	if (type === ButtonLinkTypeEnum.BUTTON) {
		return (
			<div className={joinClasses([classes.wrapperStyle, wrapperClassName])}>
				{disabled ? (
					<button
						className={classes[ButtonLinkTypeEnum.DISABLED_BUTTON]}
						type="button"
						disabled={disabled}
						onClick={() => {
							onclick && onclick()
						}}
					>
						{text}
					</button>
				) : (
					<button
						className={classes[localType]}
						type="submit"
						onClick={() => {
							onclick && onclick()
						}}
						disabled={disabled}
					>
						{text}
					</button>
				)}
			</div>
		)
	}

	return (
		<div
			className={`${
				type !== ButtonLinkTypeEnum.LINK ? classes.wrapperStyle : ''
			}`}
		>
			<Link to={link || ''} className={classes[localType]} key="logoutRedirect">
				{text}
			</Link>
		</div>
	)
}
